import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock } from '../index';

const PMBCL = () => (
    
    <ComponentWrapper>
        <StudyCopyBlock 
            title="Study Overview" 
            description={`
                <p>Phase 2, Multicenter, open-label, single-arm study</p>
                <p>KEYNOTE&#8288;-&#8288;170 study design: The efficacy of KEYTRUDA as a single agent was investigated in 53 patients with refractory or relapsed PMBCL enrolled in a multicenter, open-label, single-arm study.</p>
                <ul>
                    <li>Patients with active, noninfectious pneumonitis, an allogeneic HSCT within the past 5 years (or greater than 5 years but with symptoms of GVHD), active autoimmune disease, a medical condition that required immunosuppression, or an active infection requiring systemic therapy were ineligible for the trial.</li>
                    <li>Patients received KEYTRUDA 200&nbsp;mg intravenously Q3W until unacceptable toxicity or documented disease progression, or for up to 24 months in patients who did not progress.</li>
                    <li>Disease assessments were performed every 12 weeks and assessed by blinded independent central review according to the 2007 revised International Working Group criteria.</li>
                    <li>Efficacy was based on overall response rate and duration of response.</li>
                <ul>
            `}  
        />

        <StudyCopyBlock 
            title="Patient Characteristics:"
            description={`
                <p>KEYNOTE&#8288;-&#8288;170 (N=53) included patients with PMBCL who relapsed or were refractory to therapy, heavily pretreated, and/or had received prior autologous HSCT.</p>
                <p><strong>Refractory or relapsed</strong></p>
                <ul>
                    <li>49% had relapsed disease refractory to the last prior therapy</li>
                    <li>36% had primary refractory disease</li>
                    <li>15% had untreated relapse</li>
                <ul>
                <p><strong>Heavily pretreated</strong></p>
                <ul>
                    <li>Median number of prior lines of therapy was 3 (range: 2–8)</li>
                    <li>32% had prior radiation therapy</li>
                    <li>100% of patients received rituximab as part of a prior line of therapy</li>
                </ul>
                <p><strong>HSCT history</strong></p>
                <ul>
                    <li>26% had received prior autologous HSCT</li>
                    <li>74% were autologous-HSCT naïve</li>
                <ul>
            `} 
        />

        <StudyCopyBlock 
            definitions={`
                HSCT = hematopoietic stem cell transplantation; GVHD = graft-versus-host disease; Q3W = every three weeks.
            `}
        />
    </ComponentWrapper>
);

export default PMBCL;
