import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, EndpointDisplay, TextBlock, ComparisonTable, SecondaryCallout } from '../../components';
import PMBCL from '../../components/StudyDesign/__study-design/pmbcl'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-170 - Clinical Trial Results | HCP`,
    keywords: `pmbcl, keynote 170, pmbcl clinical trial results`,
    description: `Health care professionals may find clinical trial results for KEYNOTE-170 in patients with refractory or relapsed primary mediastinal large B-cell lymphoma (PMBCL).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/pmbcl/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠170","description":"Clinical End Points from KEYNOTE⁠-⁠170","trialDesign":"KEYNOTE⁠-⁠170 study design: The efficacy of KEYTRUDA as a single agent was investigated in 53 patients with refractory or relapsed PMBCL enrolled in a multicenter, open-label, single-arm study."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/pmbcl/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 21;
const jobCode = jobCodes[21].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/pmbcl/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical End Points from KEYNOTE&#8288;-&#8288;170' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;170' }
]

const durationOfResponseTable = {
    data: [
        [
            {
                legendTitle: 'KEYTRUDA'
            },
        ],
        [
            {
                label: 'Median DOR',
                dataPoint: 'Not Reached',
                dataSubPoint: '(range: 1.1+ to 19.2+ months)<sup>a</sup>'
            },
        ],
    ],
    footnotes: [
        {
            label: "a.",
            text: "Based on patients (n=24) with a response by independent review."
        }
    ]
}

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>

                            <EndpointDisplay title="Objective Response Rate (ORR)"
                                limitation="In 53 adult patients with refractory PMBCL, or who have relapsed after ≥2 prior lines of therapy"
                                cards={
                                    [
                                        {
                                            dataType: 'ORR',
                                            label: 'KEYTRUDA',
                                            rate: '45%',
                                            ci: '95% CI, 32–60',
                                            completeResponseRate: '11%',
                                            partialResponseRate: '34%',
                                        }
                                    ]
                                }
                            />

                            <TextBlock>
                                <h4>Additional Information</h4>
                                <p>The median follow-up time in KEYNOTE&#8288;-&#8288;170 was 9.7 months.</p>
                                <p>For the 24 responders, the median time to first objective response (complete or partial response) was 2.8 months (range: 2.1 to 8.5 months).</p>
                            </TextBlock>

                            <ComparisonTable
                                title="Duration of Response (DOR)" {...durationOfResponseTable} />

                            <SecondaryCallout
                                title='Pediatric Use'
                                text='Use of KEYTRUDA in pediatric patients is supported by evidence from adequate and well-controlled studies in adults with additional pharmacokinetic and safety data in pediatric patients.'
                                alt=""
                            />

                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <PMBCL />
                            </PageSubSection>

                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
